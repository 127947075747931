import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import '../styles/success_modal.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faCircle, faPhone} from "@fortawesome/free-solid-svg-icons";
import arrow from "../assets/arrow.png";

const SuccessModal = ({onClose}) => {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
            navigate('/management');
        }, 2000000000);

        return () => clearTimeout(timer);
    }, [navigate, onClose]);

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    ×
                </button>

                <div className="modal-text">
                    <p>Thanks for submitting your information!</p>
                    <p>We will be in contact within 24 hours with</p>
                    <p>the next steps for gaining your approval.</p>

                    <div className="office-hours">
                        <p>
                            Office Hours:
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faCircle} size={"2xs"}/>
                            <span> Monday - Friday</span>: 8am - 6pm
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faCircle} size={"2xs"}/>
                            <span> Weekends</span> by appointment only
                        </p>
                    </div>

                    <div className="contact-info">
                        <p>Contact Information:</p>
                        <p><FontAwesomeIcon icon={faPhone} size="xs"/> 0412 757 751</p>
                        <p><FontAwesomeIcon icon={faAt} size="xs"/> office@noosaairbnbapprovals.com.au</p>
                    </div>

                    <div className="management-section">
                        <h3>Need your property managed?</h3>
                        <p>See what sets our management service</p>
                        <p>apart from the competition.</p>
                        <div className={"arrow-pointing-to-button-modal"}>
                            <img src={arrow} alt=""/>
                        </div>
                        <button
                            className="management-button"
                            onClick={() => {
                                onClose();
                                navigate("/management");
                            }}
                        >
                            Management
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessModal;