import React from "react";
import Navigation from "../components/navigation";
import "../styles/prices.css";
import logo from "../assets/new_logo.png";
import {useNavigate} from "react-router-dom";

const Prices = () => {
    let navigate = useNavigate();

    const handleSubmit = () => {
        navigate("/");
    };

    return (
        <div className="main">
            <Navigation/>

            <div className="header">
                <div className="logo">
                    <img src={logo} alt="Noosa Airbnb Approvals"/>
                </div>
            </div>

            <div className="prices-content">
                <p>
                    {" "}
                    <strong>
                        Get Your Approval for Free!
                    </strong>{" "}
                </p>
                <p>
                    We waive approval fees for clients who
                    choose to have their property managed by
                    us.

                </p>
                <p>
                    Plus, your council fees can be paid in arrears using the revenue generated by your
                    property.
                </p>
            </div>

            <div className="prices-content">
                <p>
                    {" "}
                    <strong>Want to manage your property yourself?</strong>{" "}
                </p>
                <p>
                    If you need help obtaining approval but
                    prefer to manage your property independently, we can guide you through our
                    Airbnb Affiliate Program.
                </p>
                <p>
                    If referred to Airbnb by Noosa Airbnb Approvals, and if it's your first property listed
                    on Airbnb, Airbnb will pay us a referral
                    fee—covering most of your approval costs,
                    first-year license, and council tees.
                </p>
            </div>
            <div className="prices-content">
                <p style={{textAlign: "center"}}>
                    {" "}
                    <strong>Fixed Price Approvals</strong>
                </p>

                <p style={{textAlign: "center"}}>
                    Unit $1295.95
                    <br/>
                    House $1695.95
                </p>
                <p
                    style={{
                        fontSize: "10px",
                        textAlign: "center",
                        marginBottom: "20px",
                    }}
                    className="note"
                >
                    * Inc. council application fee & first year's licence fee
                </p>
            </div>
            <button onClick={handleSubmit} className="cta-button">
                Get Your Approval Here
            </button>
        </div>
    );
};

export default Prices;
