import React from "react";
import Navigation from "../components/navigation";
import "../styles/management.css";
import logo from "../assets/new_logo.png";
import { useNavigate } from "react-router-dom";
import arrow from "../assets/arrow.png";

const Management = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  const redirectToPortfolioPage=()=>{
    navigate("/portfolio");
    }

  const handleDownload = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/managementBrochureWeb.pdf`;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Management_Brochure_Web_Pdf.pdf";
    link.click();
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      <div className="management-content">
        <p>Experts in Short-Term Accommodation Management</p>
          <p>
              We offer property owners and investors a
              seamless path to obtaining holiday letting
              permissions in Noosa.
          </p>
          <p>Our full-service offering ranges from securing
              the right approvals to complete property management.
              This includes professional
              staging and photography to showcase your
              property at its best, ensuring our clients
              enjoy hassle-free, above-average returns.</p>
          <p>   We charge a 25% management fee on revenue after expenses,
              with setup costs covered by property-generated revenue.</p>
          <p> Our management strategies are tailored to
              meet individual owner needs, ensuring
              properties are marketed effectively and
              successfully.</p>
          <p>  We are committed to guest experience,
              adding personalized touches and tailored
              recommendations that drive positive reviews,
              enhance visibility, and increase
              bookings.</p>
          <p>
              Want to Learn More?
              <br/>
              Download our full management offering
              <br/>
              below to see what sets us apart.
          </p>
      </div>
        <div className={"arrow-pointing-to-button"}>
            <img src={arrow} alt=""/>
        </div>
        <button onClick={handleDownload} className="download-button">
            Management Brochure
        </button>
        <button onClick={redirectToPortfolioPage} className="portfolio-button">
            View Our Portfolio Here
        </button>
      <button onClick={handleSubmit} className="approval-button">
        Get Your Approval Here
      </button>
    </div>
  );
};

export default Management;
