import React from "react";
import Navigation from "../components/navigation";
import "../styles/contact.css";
import logo from "../assets/new_logo.png";
import { useNavigate } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faCircle, faPhone} from "@fortawesome/free-solid-svg-icons";

const Prices = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>
      
      <div className="contact-content">
          <div className="office-hours">
              <p >
                  Office Hours:
              </p>
              <p>
                  <FontAwesomeIcon icon={faCircle} size={"2xs"} />
                  <strong> Monday - Friday</strong>: 8am - 6pm
              </p>
              <p>
                  <FontAwesomeIcon icon={faCircle} size={"2xs"} />
                  <strong> Weekends</strong> by appointment only
              </p>
          </div>

          <div className="contact-info">
              <p>Contact Information:</p>
              <p><FontAwesomeIcon icon={faPhone} size="xs" /> 0412 757 751</p>
              <p><FontAwesomeIcon icon={faAt} size="xs" /> office@noosaairbnbapprovals.com.au</p>
          </div>
      </div>

      <button className="cta-button" onClick={handleSubmit}>
        Get Your Approval Here
      </button>
    </div>
  );
};

export default Prices;
