import React from 'react';
import Navigation from '../components/navigation';
import '../styles/portfolio.css';
import logo from "../assets/new_logo.png";
import slider1 from "../assets/slider1.png";
import slider2 from "../assets/slider2.png";
import slider3 from "../assets/slider3.png";
import slider4 from "../assets/slider4.png";
import {useNavigate} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import arrow from "../assets/arrow.png";

const Portfolio = () => {
    let navigate = useNavigate();

    const handleSubmit = () => {
        navigate("/");
    };
    const handleDownload = () => {
        const pdfUrl = `${process.env.PUBLIC_URL}/managementBrochureWeb.pdf`;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Management_Brochure_Web_Pdf.pdf";
        link.click();
    };
    return (
        <div className="main">
            <Navigation/>

            <div className="header">
                <div className="logo">
                    <img src={logo} alt="Noosa Airbnb Approvals"/>
                </div>
            </div>

            <div className="portfolio-content">
                <p>Here is a selection of properties under our management in Noosa</p>
                <p>Each property we manage has a tailored
                    strategy to ensure high booking rates and
                    returns. The team at Noosa Airbnb Approvals & Management goes above and
                    beyond for each of our clients.</p>

                <p>Sunshine Beach Retreat</p>
                <div className="portfolio-items">
                    <Carousel showThumbs={true} autoPlay infiniteLoop showArrows={false} showStatus={false}>
                        <div>
                            <img alt={"slide 1"} src={slider1}/>
                        </div>
                        <div>
                            <img alt={"slide 2"} src={slider2}/>
                        </div>
                        <div>
                            <img alt={"slide 3"} src={slider3}/>
                        </div>
                        <div>
                            <img alt={"slide 4"} src={slider4}/>
                        </div>
                    </Carousel>

                    {/* <div className="portfolio-item">
            <h2>Project 1</h2>
            <p>Description of Project 1, showcasing the work done and the results achieved.</p>
          </div>     */}
                </div>
                <p>
                    Sunshine beach retreat is a 2 bedroom
                    apartment that is situated in Sunshine
                    Beach. Our expert team set up this property
                    in 2023, and in its first year, it generated
                    nearly three times the income it would have
                    earned as a long-term rental.
                </p>
                <p>To find out more about what sets our
                    management service apart, download
                    our full management offering below</p>
            </div>
            <div className={"arrow-pointing-to-button-portfolio"}>
                <img src={arrow} alt=""/>
            </div>
            <button onClick={handleDownload} className="cta-button">
                Management Brochure
            </button>
            <button onClick={handleSubmit} className="cta-button">Get Your Approval Here</button>
        </div>
    );
};

export default Portfolio;
