import React from "react";
import Navigation from "../components/navigation";
import "../styles/about.css";
import logo from "../assets/new_logo.png";
import { useNavigate } from "react-router-dom";

const About = () => {
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };

  return (
    <div className="main">
      <Navigation />

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      <div className="about-content">
        <p>
          <strong>
            Dont pay $3000+ in fees to a local law
            firm to attain you're council approval!
          </strong>
        </p>

        <p>
          Noosa is a unique playing field when it comes to holiday letting, with extremely strict local laws and
          regulations compared to other holiday destinations. It can be difficult to know where to start and what's
          required before you begin short-term holiday letting in Noosa.
        </p>

        <p>
          <strong>"To hard" & "seemingly impossible"</strong>
        </p>
        
        <p>
         These are two phrases we frequently hear from property owners when discussing short-term holiday letting in
          Noosa. The Noosa Council has taken a strong stance against short-term holiday letting and has done a good
          job of making it seem impossible!
        </p>

        <p>
          <strong>At Noosa Airbnb Approvals, We Offer a
            'No Approval, No Fee' Guarantee</strong>
        </p>

        <p>We remove fear and uncertainty from the
          process, simplifying the path to getting
          your property ready for short-term letting.
         </p>

        <p>
          All we need from you is basic ownership
          information and a signature, we'll take
          care Of the rest.
        </p>

        <p>
          <strong>
           Noosa's Only Short-Term Accommodation Approval & Management Specialists
          </strong>
        </p>

        <p>
          We provide a unique opportunity and a dynamic solution for property owners and investors alike.
        </p>

        <p>
          Whether it's your holiday home or investment property, we will help you obtain the necessary Noosa Council
          short-term accommodation approval.
        </p>
        <p>
          Our management services ensure the successful and profitable continuation of holiday
          letting your Noosa property.
        </p>
      </div>

      <button onClick={handleSubmit} className="cta-button">
        Get Your Approval Here
      </button>
    </div>
  );
};

export default About;
