import React, { useMemo, useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import SuccessModal from "../components/success_modal";
import Navigation from "../components/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faPhone, faSearch } from "@fortawesome/free-solid-svg-icons";
import "../styles/home.css";
import logo from "../assets/new_logo.png";
import axios from "axios";

const Home = () => {
    const [formData, setFormData] = useState({ address: "", phone: "", email: "" });
    const [showModal, setShowModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [autocomplete, setAutocomplete] = useState(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ["places"],
    });

    const center = { lat: -26.3960671, lng: 153.0894466 };
    const radius = 60000;

    const bounds = useMemo(() => {
        if (!isLoaded) return null;

        const R = 6378137;
        const latChange = (radius / R) * (180 / Math.PI);
        const lngChange = latChange / Math.cos((center.lat * Math.PI) / 180);

        return new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(center.lat - latChange, center.lng - lngChange),
            new window.google.maps.LatLng(center.lat + latChange, center.lng + lngChange)
        );
    }, [isLoaded, center, radius]);

    const validateForm = () => {
        const errors = {};

        if (!formData.address.trim()) errors.address = '*';
        if (!formData.phone.trim()) errors.phone = '*';
        if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            errors.email = '*';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors({});

        if (!validateForm()) return;

        try {
            const response = await axios.post("https://api.noosaairbnbapprovals.com.au/send-email", formData);

            if (response.status === 200) {
                setShowModal(true);
                setFormData({ address: "", phone: "", email: "" });
            } else {
                setFormErrors((prev) => ({ ...prev, form: "Failed to send data. Please try again later." }));
            }
        } catch (error) {
            console.error("Error sending data:", error);
            setFormErrors((prev) => ({ ...prev, form: "An error occurred. Please try again." }));
        }
    };

    const onPlaceSelected = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place && place.formatted_address) {
                setFormData((prev) => ({ ...prev, address: place.formatted_address }));
            }
        }
    };

    return (
        <div className="main">
            <Navigation />
            <div className="header">
                <div className="logo">
                    <img src={logo} alt="Noosa Airbnb Approvals" />
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                {formErrors.form && <p className="form-error">{formErrors.form}</p>}

                {isLoaded && (
                    <div className="input-container">
                        <FontAwesomeIcon icon={faSearch} className="input-icon" />
                        <Autocomplete
                            onLoad={(auto) => setAutocomplete(auto)}
                            onPlaceChanged={onPlaceSelected}
                            options={{
                                componentRestrictions: { country: "AU" },
                                bounds: bounds,
                                strictBounds: true,
                            }}
                        >
                            <input
                                className="main-input"
                                type="text"
                                placeholder="Address"
                                value={formData.address}
                                onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))}
                            />
                        </Autocomplete>
                        {formErrors.address && <p className="tooltip">{formErrors.address}</p>}
                    </div>
                )}

                <div className="input-container">
                    <FontAwesomeIcon icon={faPhone} className="input-icon" />
                    <input
                        className="main-input"
                        type="tel"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))}
                    />
                    {formErrors.phone && <p className="tooltip">{formErrors.phone}</p>}
                </div>

                <div className="input-container">
                    <FontAwesomeIcon icon={faAt} className="input-icon" />
                    <input
                        className="main-input"
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                    />
                    {formErrors.email && <p className="tooltip">{formErrors.email}</p>}
                </div>

                <button type="submit">Get Your Approval</button>
            </form>

            <div className="info">
                <p className="acumin-semibold">
                    Noosa Airbnb Approvals is your fast track to attaining approval for short-term holiday letting of
                    your Noosa property.
                </p>
                <p>
                    We're <span className="acumin-semibold">Noosa's only</span> STA approval specialists.
                </p>

                <p>Guaranteed to cost less than any local law consultant or firm.</p>

                <p>
                    Applications for your Noosa short-term accommodation approval are completed and submitted within 24
                    hours of the initial consultation.
                </p>

                <div className="pricing">
                    <p className="acumin-semibold">Fixed price approvals</p>
                    <p>Unit $1295.95</p>
                    <p>House $1695.95</p>
                </div>
                <div className="licence">*inc. council application & first year's licence fee</div>
            </div>

            {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
        </div>
    );
};

export default Home;